


















































import JnFormularioSearch from "@/views/search/FormularioSearch.vue";
import JnCardsStrip from '@/views/common/CardsStrip.vue';
import JnSearchResults from '@/views/search/SearchResults.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import { SearchData } from '@/models/search.model';
import { Categories } from '@/models/enums.model';
import { Testata } from '@/models/testata.model';
import MetaInfo from 'vue-meta';

@Component({
  components: {
    JnFormularioSearch,
    JnCardsStrip,
    JnSearchResults
  },
  metaInfo(this: JnFormulario): MetaInfo {
    return { title: 'JuraNews - Formulario' };
  },
})
export default class JnFormulario extends Vue {
  testata?: Testata;

  searchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    keywords: [],
    perPage: 20,
    from: 0,
    idCategory: [Categories.FORMULE],
  });

  ultimeFormuleSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    size: 100,
    withText: true,
    perPage: 8,
    idCategory: [Categories.FORMULE],
  });

  created() {
    this.testata = JnService.getTestata(Categories.FORMULE);
  }
}
